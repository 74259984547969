import styled from "styled-components";
import { ColorGrey10, ColorGrey60, ColorUltraviolet50 } from "@sermo/design-tokens";
import { MobileQuery } from "@Frontend/queries";
import { Text } from "@sermo/ui-components";

export const InfoFooter = styled.footer`
	min-height: 136px;
	background-color: ${ColorGrey10};
`;

export const WrapContent = styled.div`
	max-width: 1080px;
	margin: 0 auto;
	padding: 24px;
	display: flex;

	@media ${MobileQuery} {
		padding: 40px 24px;
		flex-direction: column-reverse;
	}

	& > div {
		flex-basis: 50%;

		@media ${MobileQuery} {
            flex-basis: 100%;
        }
	}
`;

const BS01Text = styled(Text).attrs({
	forwardedAs: "p",
	kind: "bodyShort01",
})`
	line-height: 21px;
	color: ${ColorGrey60};
`;

export const Copyright = styled(BS01Text)`
	text-align: left;
`;


export const Rebrand = styled(BS01Text)`
	line-height: 22px;
	text-align: left;
`;

export const WrapCopyRightRebrand = styled.div`
	@media ${MobileQuery} {
		margin-top: 32px;
        display: flex;
        flex-direction: column-reverse;
    }

	p + p {
		margin-top: 16px;

		@media ${MobileQuery} {
            margin-top: 0;
            margin-bottom: 8px;
        }
	}
`;

export const Links = styled(BS01Text).attrs({ forwardedAs: "a" })`
	text-decoration: none;

	&:hover {
		color: ${ColorUltraviolet50};
	}
`;

export const WrapLinks = styled.div`
	display: flex;
	justify-content: flex-end;

	@media ${MobileQuery} {
        flex-direction: column;
    }

	& > ${Links} {
		margin-left: 24px;

		@media ${MobileQuery} {
            margin-left: 0;

			& + ${Links} {
                margin-top: 32px;
            }
        }
	}
`;
