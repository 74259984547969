import styled from "styled-components";
import { MobileQuery } from "@Frontend/queries";
import { ColorGrey60, ColorGrey100 } from "@sermo/design-tokens";

export const TestimonialWrap = styled.section`
	min-height: 356px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media ${MobileQuery} {
		min-height: auto;
		padding: 48px 24px;
	}
`;

export const QuoteText = styled.div`
	font-family: Sailec;
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;
	letter-spacing: -0.8px;
	text-align: left;

	@media ${MobileQuery} {
		font-size: 16px;
		line-height: 20px;
		letter-spacing: -0.18px;
	}
`;

export const QuoteFlex = styled.div`
	display: flex;
	justify-content: center;
`;

export const QuoteContent = styled.div`
	width: 726px;
	margin-left: 42px;

	@media ${MobileQuery} {
		width: auto;
		margin-left: 24px;
	}
`;

export const QuoteIcon = styled.div`
	width: 76px;
	height: 24px;
	margin-top: 12px;
	background-image: url(assets/images/quoteIcon.svg);
	background-repeat: no-repeat;
	background-position: center;

	@media ${MobileQuery} {
		min-width: 24px;
		min-height: 24px;
		margin-top: 0;
		background-size: contain;
	}
`;

export const QuoteeWrap = styled.div`
	margin-top: 24px;
	display: flex;
`;

export const QuoteeImage = styled.div`
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background-image: url(assets/images/quoteeImage.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@media ${MobileQuery} {
		width: 32px;
		height: 32px;
	}
`;

export const QuoteeInfo = styled.div`
	margin-left: 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const QuoteeName = styled.div`
	font-family: Sailec;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: -0.2px;
	text-align: left;
	color: ${ColorGrey100};

	@media ${MobileQuery} {
		font-size: 12px;
		letter-spacing: -0.15px;
	}
`;

export const QuoteeTitle = styled.div`
	font-family: Sailec;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0px;
	text-align: left;
	color: ${ColorGrey60};

	@media ${MobileQuery} {
		font-size: 12px;
		line-height: 16px;
	}
`;