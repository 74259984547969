import styled from "styled-components";
import { MobileQuery } from "@Frontend/queries";
import { Text } from "@sermo/ui-components";

export const Title = styled(Text).attrs({
	forwardedAs: "h2",
	kind: "heading06",
})`
	font-size: 32px;
	font-weight: 700;
	line-height: 48px;
	letter-spacing: -0.05em;

	@media ${MobileQuery} {
		font-size: 24px;
		line-height: 36px;
		letter-spacing: -0.8px;
	}
`;

export const Description = styled(Text).attrs({
	forwardedAs: "p",
	kind: "bodyLong03",
})`
	@media ${MobileQuery} {
		font-size: 16px;
		line-height: 24px;
	}
`;

export const TitleAndDescription = styled.div`
	${({ $pad }) => `padding: ${$pad};`};

	& > *:not(:first-child) {
		${({ $midPad }) => `padding: ${$midPad};`};
	}
`;

export const FlexWrap = styled.div`
	display: flex;
`;