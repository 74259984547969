import styled from "styled-components";
import { ColorGrey100 } from "@sermo/design-tokens";
import { Description } from "@Frontend/shared.styles";
import { MobileQuery } from "@Frontend/queries";
import { Text } from "@sermo/ui-components";

export const StyledSection = styled.section`
	min-height: 632px;
	padding: 64px;

	@media ${MobileQuery} {
		flex-direction: column;
		padding: 32px 24px;
		align-items: flex-start;
	}
`;

export const Wrap = styled.div`
	max-width: 1080px;
	margin: 0 auto;
	display: flex;
	align-items: center;

	@media ${MobileQuery} {
		flex-direction: column;
	}
`;

export const WrapContent = styled.div`
	width: 504px;
	height: 490px;
	padding-right: 64px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	font-family: Sailec;
	color: ${ColorGrey100};

	& > *:not(:first-child) {
		padding-top: 24px;
    }

	@media ${MobileQuery} {
		width: auto;
		height: auto;
		padding-bottom: 32px;
		padding-right: 0;
	}
`;

export const BulletTitle = styled(Text).attrs({
	forwardedAs: "h3",
	kind: "heading04",
})`
	font-family: Sailec;
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: -0.7px;
	text-align: left;
	color: ${ColorGrey100};

	@media ${MobileQuery} {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.16px;
    }
`;

export const BulletDescription = styled(Description)`
	line-height: 30px;

	@media ${MobileQuery} {
		line-height: 24px;
	}
`;

export const BulletNumIcon = styled.div`
	min-width: 32px;
	height: 32px;
	background-image: url("assets/images/num${({ num }) => num}.svg");

	@media ${MobileQuery} {
		min-width: 24px;
		height: 24px;
		background-size: contain;
	}
`;

export const WrapBulletInfo = styled.div`
	max-width: 320px;
	padding-left: 24px;

	& > *:not(:first-child) {
		padding-top: 8px;
	}

	@media ${MobileQuery} {
		max-width: 100%;
		padding-left: 16px;
	}
`;

export const StockImage = styled.div`
	width: 504px;
	height: 332px;
	border-radius: 8px;
	background-image: url("assets/images/stockMemberMNOW.svg");

	@media ${MobileQuery} {
		width: 100%;
		height: calc(100vw * 4/7);
		background-size: cover;
	}
`;