import styled from "styled-components";
import { ColorGrey00, ColorGrey100 } from "@sermo/design-tokens";
import { Description } from "@Frontend/shared.styles";
import { MobileQuery } from "@Frontend/queries";

export const Section = styled.section`
	min-height: 730px;
	padding: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${ColorGrey00};

	@media ${MobileQuery} {
		min-height: auto;
		padding: 32px 24px;
	}
`;

export const Wrap = styled.div`
	max-width: 1080px;
	min-height: 602px;
	font-family: Sailec;
	color: ${ColorGrey100};

	& > div > ${Description} {
		max-width: 670px;

		@media ${MobileQuery} {
            max-width: 100%;
        }
    }

	@media ${MobileQuery} {
		width: auto;
		min-height: auto;
    }
`;

export const WrapBlurbs = styled.div`
	min-height: 520px;
	padding-top: 64px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@media ${MobileQuery} {
		min-height: 600px;
		padding-top: 0;
    }

	& > article {
		flex-basis: 33.33%;

		@media ${MobileQuery} {
			flex-basis: 100%;

			&:not(:first-child) {
                margin-top: 24px;
            }
        }
    }

	& > article > ${Description} {
		max-width: 85%;
		padding-top: 24px;

		@media ${MobileQuery} {
			max-width: 100%;
			padding-top: 0;
			padding-left: 16px;
        }
	}
`;

export const Blurb = styled.article`
	@media ${MobileQuery} {
        display: flex;
    }
`;

export const ImageIcon = styled.div`
	width: 64px;
	height: 64px;
	background-image: url(assets/images/${({ $name }) => $name}.svg);

	@media ${MobileQuery} {
		width: 32px;
		min-width: 32px;
		height: 32px;
		background-size: cover;
	}
`;