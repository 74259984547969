import { Button, Icon, LogoIcon } from "@sermo/ui-components";
import React from "react";
import styled from "styled-components";

const Header = styled.header`
	width: 100%;
	height: var(--top-nav-height);
	padding: 24px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
`;

export const TopNav = () => {
	const handleClick = () => {
		LoginUrl && window?.open(LoginUrl, "_blank"); // eslint-disable-line no-undef
	};

	return (
		<Header>
			<Icon
				height={24}
				src={LogoIcon}
				width={76}
			/>
			<Button
				kind="secondary"
				size="medium"
				onClick={handleClick}
			>
				Login
			</Button>
		</Header>
	);
};