import React from "react";
import { Title, Description, TitleAndDescription } from "@Frontend/shared.styles";
import {
	Blurb,
	ImageIcon,
	Section,
	Wrap,
	WrapBlurbs,
} from "./WhyJoinSermo.styles";

export const WhyJoinSermo = () => {
	return (
		<Section>
			<Wrap>
				<TitleAndDescription $pad="0 0 40px 0" $midPad="16px 0 0 0">
					<Title>Why join Sermo</Title>
					<Description>
						We conduct research for leading companies in the medical industry
						- your professional opinion may influence decisions that significantly impact
						healthcare innovations and ultimately improve the practice of medicine globally.
					</Description>
				</TitleAndDescription>
				<WrapBlurbs>
					<Blurb>
						<ImageIcon $name="upper-body" />
						<Description>
							There are 1.3 million members worldwide.
						</Description>
					</Blurb>
					<Blurb>
						<ImageIcon $name="globe" />
						<Description>
							We conduct more healthcare surveys than any other single company globally
							— offering you the best selection.
						</Description>
					</Blurb>
					<Blurb>
						<ImageIcon $name="receipt" />
						<Description>
							You will be compensated for your time and efforts.
							Last year, members earned over $20 million dollars collectively.
						</Description>
					</Blurb>
					<Blurb>
						<ImageIcon $name="credit-card" />
						<Description>
							You may select from multiple payment methods including
							direct deposit, gift cards, pre-paid debit cards, or checks.
						</Description>
					</Blurb>
					<Blurb>
						<ImageIcon $name="shield" />
						<Description>
							Your privacy and identity are always protected,
							and you may unsubscribe at any time.
						</Description>
					</Blurb>
					<Blurb>
						<ImageIcon $name="drug" />
						<Description>
							We are proud members and abide by the Codes of Conduct
							of leading professional research associations.
						</Description>
					</Blurb>
				</WrapBlurbs>
			</Wrap>
		</Section>
	);
};