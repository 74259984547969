import React from "react";
import styled from "styled-components";
import { ColorGrey100, ColorWhite } from "@sermo/design-tokens";
import { MobileQuery } from "@Frontend/queries";

const Wrap = styled.div`
	padding: 40px 64px 0;

	@media ${MobileQuery} {
		padding-top: 16px;
	}
`;

const Content = styled.div`
	max-width: 1080px;
	margin: 0 auto;
	padding: 24px 0 ;
	background-color: ${ColorWhite}99;
	border-radius: 8px;
	font-family: Sailec;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.18px;
	text-align: center;
	color: ${ColorGrey100};

	@media ${MobileQuery} {
		padding: 16px;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: -0.15px;
	}
`;

export const RenderMessages = () => {
	const messages = document.getElementById("render-messages");

	if (!messages?.children?.length
		|| !messages?.children?.[0]?.innerText
	) {
		return null;
	}

	return <Wrap id="feedback"><Content>{messages.children[0].innerText}</Content></Wrap>;
}