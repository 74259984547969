import styled from "styled-components";
import { MobileQuery } from "@Frontend/queries";
import { ColorUltraviolet50 } from "@sermo/design-tokens";
import { Text } from "@sermo/ui-components";

export const Wrap = styled.section`
	height: auto;
	background-image: url(assets/images/mnowSermoHeroPhone.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@media ${MobileQuery} {
		background-image: unset;
		background-color: rgba(218, 233, 236, 1);
	}
`;

export const WrapContent = styled.div`
	margin-right: 30vw;
	padding: 64px;

	@media ${MobileQuery} {
		margin: 0;
		padding: 32px 24px;
	}
`;

export const Content = styled.div`
	max-width: 660px;
	min-height: 40vw;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media ${MobileQuery} {
		max-width: unset;
		min-height: 0;
	}

	& > *:not(:last-child) {
		margin-bottom: 24px;

		@media ${MobileQuery} {
            margin-bottom: 8px;
        }
    }
`;

export const SuperTitle = styled(Text).attrs({
	forwardedAs: "h2",
	kind: "bodyShort02",
})`
	font-weight: 500;
	letter-spacing: -0.18px;
	color: ${ColorUltraviolet50};

	@media ${MobileQuery} {
		font-size: 12px;
		line-height: 16px;
		letter-spacing: -0.15px;
    }
`;

export const Title = styled(Text).attrs({
	forwardedAs: "h1",
	kind: "heading07",
})`
	font-weight: 700;

	@media ${MobileQuery} {
		font-size: 32px;
		line-height: 40px;
		letter-spacing: -0.95px;
    }
`;

export const WrapButton = styled.div`
	padding-top: 16px;

	@media ${MobileQuery} {
		padding-top: 24px;
	}
`;