import React from "react";
import { Button } from "@sermo/ui-components";
import { RenderMessages } from "@components/RenderMessages";
import { Description } from "@Frontend/shared.styles";
import {
	Content,
	SuperTitle,
	Title,
	Wrap,
	WrapButton,
	WrapContent,
} from "./MnowIsNowSermo.styles";

export const MnowIsNowSermo = () => {
	return (
		<Wrap>
			<RenderMessages />
			<WrapContent>
				<Content>
					<SuperTitle>MNOW is now Sermo</SuperTitle>
					<Title>Participate in paid online medical market research studies</Title>
					<Description>
						Sermo is the world’s leading online community of practicing physicians, nurses, dentists,
						optometrists, pharmacists, veterinarians, payers and many other professionals who make an
						impact in healthcare by participating in paid online medical market research studies.
					</Description>
					<WrapButton>
						<a href={`${sermo.mnowAppBaseUrl}register`}>
							<Button
								kind="primary"
								size="medium"
							>
								Register
							</Button>
						</a>
					</WrapButton>
				</Content>
			</WrapContent>
		</Wrap>
	);
};