// run with peter
export const DesktopMinWidth = 960;
export const DesktopQuery = `(min-width: ${DesktopMinWidth}px)`;
export const MobileMaxWidth = DesktopMinWidth - 1;
export const MobileMinWidth = 360;
export const MobileQuery = `(max-width: ${DesktopMinWidth - 1}px)`;

// membership (partial)
export const mobileTabletBreak = 850;
export const tabletDesktopBreak = 1200;
export const StyledMobileAndTabletPortraitQuery = `@media (min-width: ${mobileTabletBreak}px) and (max-width: ${tabletDesktopBreak}px) and (orientation: portrait), (max-width: ${mobileTabletBreak - 1}px)`;
