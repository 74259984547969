import React from "react";
import { FlexWrap, Description, Title, TitleAndDescription } from "@Frontend/shared.styles";
import {
	BulletDescription,
	BulletNumIcon,
	BulletTitle,
	StockImage,
	StyledSection,
	Wrap,
	WrapBulletInfo,
	WrapContent,
} from "./HowItWorks.styles";

export const HowItWorks = () => {
	return (
		<StyledSection>
			<Wrap>
				<WrapContent>
					<TitleAndDescription $pad="0 0 16px 0" $midPad="16px 0 0 0">
						<Title>How it works</Title>
						<Description>
							Sermo is a free online research portal that allows you to complete surveys at your convenience.
						</Description>
					</TitleAndDescription>
					<FlexWrap>
						<BulletNumIcon num="1" />
						<WrapBulletInfo>
							<BulletTitle>We invite you to a survey</BulletTitle>
							<BulletDescription>
								Invitations tailored to your specialty will be emailed to you.
							</BulletDescription>
						</WrapBulletInfo>
					</FlexWrap>
					<FlexWrap>
						<BulletNumIcon num="2" />
						<WrapBulletInfo>
							<BulletTitle>You participate</BulletTitle>
							<BulletDescription>
								Complete the survey at a time convenient for you.
							</BulletDescription>
						</WrapBulletInfo>
					</FlexWrap>
					<FlexWrap>
						<BulletNumIcon num="3" />
						<WrapBulletInfo>
							<BulletTitle>You are rewarded</BulletTitle>
							<BulletDescription>
								Honoraria can be redeemed through a variety of payment methods.
							</BulletDescription>
						</WrapBulletInfo>
					</FlexWrap>
				</WrapContent>
				<StockImage />
			</Wrap>
		</StyledSection>
	);
};