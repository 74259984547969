import React from "react";
import { TopNav } from "@components/TopNav";
import { InfoFoot } from "@components/InfoFoot";
import styled from "styled-components";
import { MobileQuery } from "@Frontend/queries";

const LayoutWrap = styled.div`
	padding-top: var(--top-nav-height);
	overflow: auto;

	button {
		text-transform: unset;
		text-shadow: unset;
	}

	footer {
		text-align: unset;
	}

	@media ${MobileQuery} {
		min-width: auto;
	}
`;

export const Layout = ({ children }) => {
	return (
		<LayoutWrap>
			<TopNav />
			{children}
			<InfoFoot />
		</LayoutWrap>
	);
};