import React from "react";
import {
	Copyright,
	InfoFooter,
	Links,
	Rebrand,
	WrapContent,
	WrapCopyRightRebrand,
	WrapLinks,
} from "./InfoFoot.styles";
export const InfoFoot = () => {
	return (
		<InfoFooter>
			<WrapContent>
				<WrapCopyRightRebrand>
					<Copyright>Copyright © 2023 Sermo</Copyright>
					<Rebrand>
						This site was previously MNOW.
						Founded in 2012, MNOW has now been rebranded to join as part of Sermo, its original parent company.
					</Rebrand>
				</WrapCopyRightRebrand>
				<WrapLinks>
					<Links href="https://www.sermo.com/contact/">Contact us</Links>
					<Links href="https://www.sermo.com/privacy-policy/">Privacy policy</Links>
					<Links href="https://www.sermo.com/terms-of-service/">Terms and conditions</Links>
				</WrapLinks>
			</WrapContent>
		</InfoFooter>
	);
};