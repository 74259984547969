import React from "react";
import {
	QuoteFlex,
	QuoteIcon,
	QuoteContent,
	QuoteText,
	QuoteeWrap,
	QuoteeImage,
	QuoteeInfo,
	QuoteeName,
	QuoteeTitle,
	TestimonialWrap,
} from "./Testimonial.styles";

export const Testimonial = () => {
	return (
		<TestimonialWrap>
			<QuoteFlex>
				<QuoteIcon></QuoteIcon>
				<QuoteContent>
					<QuoteText>
						Sermo surveys are straight-forward, and the honoraria is reflected in your account immediately.
						I request a check once the balance reaches a certain amount, and they process it quickly.
					</QuoteText>
					<QuoteeWrap>
						<QuoteeImage></QuoteeImage>
						<QuoteeInfo>
							<QuoteeName>Guy Jones M.D.</QuoteeName>
							<QuoteeTitle>Oncology, U.S.</QuoteeTitle>
						</QuoteeInfo>
					</QuoteeWrap>
				</QuoteContent>
			</QuoteFlex>
		</TestimonialWrap>
	);
};