import { datadogRum } from "@datadog/browser-rum";
import { Theme } from "@sermo/ui-components";
import React from "react";
import { createGlobalStyle } from "styled-components";
import { HowItWorks } from "@components/HowItWorks";
import { Layout } from "@components/Layout";
import { MnowIsNowSermo } from "@components/MnowIsNowSermo";
import { Testimonial } from "@components/Testimonial";
import { WhyJoinSermo } from "@components/WhyJoinSermo";

const DDEnvironmentVariables = {
	development: {
		env: "development",
		sessionSampleRate: 0,
		sessionReplaySampleRate: 0,
		traceSampleRate: 0,
		allowedTracingUrls: [
			(url) => url.startsWith("http://localhost:32002"),
			(url) => url.startsWith("http://localhost:5001")
		]
	},
	internal: {
		env: "internal",
		sessionSampleRate: 10,
		sessionReplaySampleRate: 10,
		traceSampleRate: 20,
		allowedTracingUrls: [
			(url) => url.startsWith("https://internal-membership-mnow.stage.s3o.red"),
		]
	},
	staging: {
		env: "staging",
		sessionSampleRate: 10,
		sessionReplaySampleRate: 10,
		traceSampleRate: 20,
		allowedTracingUrls: [
			(url) => url.startsWith("https://staging-membership-mnow.stage.s3o.red"),
		]
	},
	production: {
		env: "production",
		sessionSampleRate: 10,
		sessionReplaySampleRate: 10,
		traceSampleRate: 20,
		allowedTracingUrls: [
			(url) => url.startsWith("https://mnow.com"),
			(url) => url.startsWith("https://www.mnow.com"),
		]
	}
}

if (window?.sermo?.env) {
	datadogRum.init({
		...DDEnvironmentVariables[window?.sermo?.env],
		applicationId: "601c0d84-9fea-4111-8bb4-7c76357877f0",
		clientToken: "pub515d500f241dd64ce15fc7b48f63a74a",
		site: "datadoghq.com",
		service: "frontend",
		version: window?.sermo?.versionHash,
		trackInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input"
	});

	datadogRum.startSessionReplayRecording();
}

const MnowGlobalStyle = createGlobalStyle`
	html {
		scroll-behavior: smooth;
		--top-nav-height: 88px;
	}

	input, textarea {
		margin: unset;
	}

	footer p {
		display: block;
	}

	p {
		margin: unset;
	}
`;

export const App = () => (
	<Theme>
		<MnowGlobalStyle />
		<Layout>
			<MnowIsNowSermo />
			<HowItWorks />
			<WhyJoinSermo />
			<Testimonial />
		</Layout>
	</Theme>
);